import React, { useState } from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import achievementCardStyle from "assets/jss/material-kit-react/components/AchievementCardStyle.jsx"
import LibraryBooks from "@material-ui/icons/LibraryBooks"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Close from "@material-ui/icons/Close"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "../CustomButtons/Button"
import Slide from "@material-ui/core/Slide"
import LocationOn from "@material-ui/icons/LocationOn"
import Carousel from "react-slick"

const Transition = (props) => <Slide direction="down" {...props} />

const AchievementCard = ({ ...props }) => {
  const {
    classes,
    title,
    category,
    showcaseImage,
    images,
  } = props
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const handleOpenModal = () => {
    setIsVisibleModal(true)
  }
  const handleCloseModal = () => {
    setIsVisibleModal(false)
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  }

  return (
    <div>
      <div
        className={classes.infoArea}
        onClick={handleOpenModal}
      >
        <img
          src={showcaseImage}
          alt="..."
          className={classes.imgContainer + " " + " " + classes.imgCover}
        />
        <div className={classes.infoWrapper}>
          <p className={classes.description}>{category}</p>
          <h2 className={classes.title}>{title}</h2>
        </div>
      </div>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={isVisibleModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseModal}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          {/*<h4 className={classes.modalTitle}>Modal title</h4>*/}
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <Carousel {...settings}>
            {images.map(image => (
              <div>
                <img
                  src={image}
                  alt="First slide"
                  className="slick-image"
                />
                {/*<div className="slick-caption">*/}
                {/*  <div className={classes.carouselImageCaption}>*/}
                {/*    <p>Planche</p>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            ))}
          </Carousel>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={handleCloseModal}
            color="danger"
            simple
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

AchievementCard.defaultProps = {}

AchievementCard.propTypes = {
  classes: PropTypes.object.isRequired,
  showcaseImage: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default withStyles(achievementCardStyle)(AchievementCard)
