import {
  grayColor,
  title,
} from "assets/jss/material-kit-react.jsx"
import imagesStyles from "../imagesStyles"
import React from "react"

const AchievementCardStyle = {
  ...imagesStyles,
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0 0 50px 0",
    cursor: "pointer",
    "&:hover,&:focus": {
      transform: "scale(1.02)",
    },
  },
  infoWrapper: {
    color: grayColor,
    overflow: "hidden",
    textAlign: "left",
  },
  carouselImageCaption: {
    background: "rgb(0 0 0 / 50%)",
    borderRadius: 5
  },
  title: {
    ...title,
    marginTop: 0,
    lineHeight: "42px",
    fontWeight: 400,
    overflow: "hidden",
  },
  description: {
    color: "#3f3f3f",
    overflow: "hidden",
    margin: "26px 0px 18px 0",
    fontSize: "10px",
    fontWeight: "400",
    letterSpacing: 1,
  },
  imgContainer: {
    width: "100%",
    maxWidth: 400,
    height: 250,
  },
}

export default AchievementCardStyle
