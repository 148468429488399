import React, { useState } from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import Chat from "@material-ui/icons/Chat"
import VerifiedUser from "@material-ui/icons/VerifiedUser"
import Fingerprint from "@material-ui/icons/Fingerprint"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"

import achievementsStyle from "assets/jss/material-kit-react/views/landingPageSections/achievementsStyle.jsx"
import AchievementCard from "../../../components/AchievementCard"
import CalifornianCafeShowcase from "assets/img/achievements/californian-cafe-showcase.jpeg"
import ShopShowcase from "assets/img/achievements/shop-showcase.jpeg"
import HostelRoomShowcase from "assets/img/achievements/hostel-room-showcase.jpeg"
import Button from "../../../components/CustomButtons/Button"

const AchievementsSection = (props) => {
  const { classes } = props
  const [isMinimized, setIsMinimized] = useState(true)
  const achievements = [
    {
      title: "Décoration et réagencement d'un café",
      category: "RÉNOVATION",
      showcaseImage: CalifornianCafeShowcase,
      images: [CalifornianCafeShowcase, ShopShowcase, HostelRoomShowcase],
    },
    {
      title: "Décoration totale d'une petite boutique de vêtements",
      category: "RÉALISATION",
      showcaseImage: ShopShowcase,
      images: [CalifornianCafeShowcase, ShopShowcase, HostelRoomShowcase],
    },
    {
      title: "Réalisation d'une chambre d'hotel à thème tropicale",
      category: "RÉNOVATION",
      showcaseImage: HostelRoomShowcase,
      images: [CalifornianCafeShowcase, ShopShowcase, HostelRoomShowcase],
    },
    {
      title: "Décoration et réagencement d'un café",
      category: "RÉNOVATION",
      showcaseImage: CalifornianCafeShowcase,
      images: [CalifornianCafeShowcase, ShopShowcase, HostelRoomShowcase],
    },
    {
      title: "Décoration totale d'une petite boutique de vêtements",
      category: "RÉALISATION",
      showcaseImage: ShopShowcase,
      images: [CalifornianCafeShowcase, ShopShowcase, HostelRoomShowcase],
    },
    {
      title: "Réalisation d'une chambre d'hotel à thème tropicale",
      category: "RÉNOVATION",
      showcaseImage: HostelRoomShowcase,
      images: [CalifornianCafeShowcase, ShopShowcase, HostelRoomShowcase],
    },
  ]
  const achievementsToShow = isMinimized ? achievements.slice(0, 3) : achievements
  const handleShowAll = e => {
    e.preventDefault()
    setIsMinimized(false)
  }
  return (
    <div className={classes.section} id="achievements">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Mes réalisations</h2>
          <h5 className={classes.description}>
            Ceci est le paragraphe où vous pouvez écrire plus de détails sur votre
            produit. Gardez votre utilisateur engagé en fournissant des informations significatives
            informations. N'oubliez pas qu'à ce stade, l'utilisateur est curieux,
            sinon il ne ferait pas défiler pour arriver ici. Ajoutez un bouton si vous le souhaitez l'utilisateur d'en
            voir plus.
          </h5>
        </GridItem>
      </GridContainer>
      <div className={classes.cardsSection}>
        <GridContainer>
          {achievementsToShow.map(({ title, category, showcaseImage, images }) => (
            <GridItem xs={12} sm={12} md={4}>
              <AchievementCard
                title={title}
                category={category}
                showcaseImage={showcaseImage}
                images={images}
              />
            </GridItem>
          ))}
        </GridContainer>
        {isMinimized && (
          <Button
            color="primary"
            onClick={handleShowAll}
          >
            En voir plus
          </Button>
        )}
      </div>
    </div>
  )
}

export default withStyles(achievementsStyle)(AchievementsSection)
